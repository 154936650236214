import React, { useState } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';
// import sanitizeHtml from 'sanitize-html';
// import { colors, sizes } from '../utils/global';
// import { Error, TextRes } from './Shared';
import { useForm } from '@formcarry/react';

const Form = styled.form`
  display: flex;
  font-weight: 400;
  flex-direction: column;
  input, textarea{
    outline: 0;
  border: 1px #eee solid;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.5rem;
  }
`;

const Button = styled.button`
  background: #274686;
  cursor: pointer;
  outline: 0;
  text-transform: uppercase;
  color: white;
  border: none;
  border-radius: 2rem;
  margin: 1rem 5rem;
  padding: 0.5rem;
  transition: background ease 0.3s;
  &:hover {
    background: #1e3668;
  }
  @media (max-width: 900px) {
    margin: 1rem 2rem;
  }
`;
const CaptchaWrap = styled.div`
  margin: 1rem auto;
`;

const NotifWrap = styled.div`
  text-align: center;
  height: 1.75rem;
`;

const Error = styled.div`
  color: red;
`;

const TextRes = styled.div``;

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { state, submit } = useForm({
    id: 'EF7-SlaAbTmZ',
  });
  const [recaptcha, setRecaptcha] = useState(true);
  const [error, setError] = useState('');
  const [res, setRes] = useState('');
  const [loading, setLoading] = useState(false);

  console.log('state', state);

  React.useEffect(() => {
    if (state.submitted) {
      setLoading(false);
      setRes('Thank you. Your message has been sent.');
    } else if (state.error) {
      setLoading(false);
      setRes(
        'Sorry, something went wrong. Please try re-sending the message or reloading the page to try again.'
      );
    }
  }, [state]);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('inside submit');
    let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!re.exec(email)) {
      setError('Please enter a valid email');
      // } else if (!name || !email || !message || !recaptcha) {
    } else if (!name || !email || !message) {
      console.log('inside else if');
      if (!name) {
        setError('Please enter your name');
      } else if (!email) {
        setError('Please enter your email');
        // } else if (!recaptcha) {
        //   setError('Please verify the reCAPTCHA');
      } else if (!message) {
        setError('Please enter your message');
      }
    } else {
      setError('');
      submit(e);

      // FOR FORMSPREE - WORKS
      // setError('');
      // setLoading(true);
      // const form = e.target;
      // const data = new FormData(form);
      // const xhr = new XMLHttpRequest();
      // xhr.open(form.method, form.action);
      // xhr.setRequestHeader('Accept', 'application/json');
      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState !== XMLHttpRequest.DONE) return;
      //   if (xhr.status >= 200 && xhr.status < 300) {
      //     form.reset();
      //     setLoading(false);
      //     setRes('Thank you. Your message has been sent.');
      //   } else {
      //     setLoading(false);
      //     setRes(
      //       'Sorry, something went wrong. Please try re-sending the message or reloading the page to try again.'
      //     );
      //   }
      // };
      // xhr.send(data);
    }
  };

  return (
    <Form
      className="kwes-form"
      onSubmit={onSubmit}
      // action="https://formspree.io/xqkyllrg" // FORMSPREE WORKS
      // action="https://app.99inbound.com/api/e/0rhjAxOO"
      // action="https://kwes.io/api/foreign/forms/roMV5SyZgDmwWfeT5Nz8"
      // method="POST"
    >
      <input
        type="text"
        name="sendername"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        rows="3"
        name="message"
        placeholder="Your Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <CaptchaWrap>
        {/* <ReCAPTCHA
          sitekey={process.env.GATSBY_CAPTCHA_KEY}
          onChange={() => setRecaptcha(!recaptcha)}
        /> */}
      </CaptchaWrap>

      {loading ? <div style={{ margin: 'auto' }}>Loading...</div> : undefined}

      <NotifWrap>
        {error && <Error>{error}</Error>}
        {res && <TextRes>{res}</TextRes>}
      </NotifWrap>

      <Button type="submit">Send</Button>
    </Form>
  );
};

export default ContactForm;
