import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: flex;
  font-weight: 600;
  margin-right: 0.25rem;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  color: #222;
  margin: 0 0 1.5rem 0;
  font-size: 0.8rem;
`;

const NarrativeDetails = ({
  title,
  year,
  director,
  editor,
  writer,
  starring,
  timeLength,
  winner
}) => {
  let wd = writer === director ? writer : null;
  let wde = writer === director && director === editor ? writer : null;

  const WDE = () => {
    return (
      <>
        {wde ? (
          <Row>
            <Span>Writer / Director / Editor</Span> {wde}
          </Row>
        ) : (
          <>
            <Row>
              <Span>Writer / Director </Span> {wd}
            </Row>
            <Row>
              <Span>Editor </Span> {editor}
            </Row>
          </>
        )}
      </>
    );
  };

  return (
    <Text>
      <Row>
        <Span>{title} </Span> {year} - {timeLength}min
      </Row>
      <WDE />
      <Row>
        <Span>Starring </Span>{' '}
        {starring.map((star, idx) => (idx > 0 ? ', ' : '') + star)}
      </Row>
      {winner && <Row>{winner}</Row>}
    </Text>
  );
};

export default NarrativeDetails;
