module.exports = {
  // Meta Data
  siteTitle: 'David Formentin',
  siteTitleAlt: 'DavidFormentin',
  siteShortName: 'DavidFormentin',
  siteLogo: '/social/avatar.png',
  siteBanner: '/social/banner.jpg',
  siteUrl: 'https://davidformentin.com',
  pathPrefix: '/',
  siteDescription:
    'David Formentin',
  // IDs
  // Manifest
  themeColor: '#3498DB',
  backgroundColor: '#2e3246',
};
