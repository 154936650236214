import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: flex;
  font-weight: 600;
  margin-right: 0.25rem;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

const Text = styled.div`
  color: #222;
  margin: 0 0 1.5rem 0;
  font-size: 0.8rem;
`;

const BrandDetails = (props) => (
  <Text>
    <Row>
      <Span>{props.brand} </Span>
      <div>{props.title}</div>
    </Row>
  </Text>
);

export default BrandDetails;
