import React from 'react';

export const Github = () => (
  <svg viewBox="0 0 512 512" height="30">
    <path
      fill="#f0f0f0"
      d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"
    />
  </svg>
);

export const Linkedin = ({color}) => (
  <svg viewBox="0 0 512 512" height="40">
    <path
      fill="#f0f0f0"
      d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"
    />
  </svg>
);

export const Twitter = () => (
  <svg viewBox="0 0 512 512" height="40">
    <path
      fill="#f0f0f0"
      d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z"
    />
  </svg>
);

export const Logo = () => (
  <svg viewBox="0 0 513.09 411.4">
    <g>
      <g>
        <path d="M384.56,7.69a7.12,7.12,0,0,0-7.87-6.9l-4,.44c-52.19,5.95-90.89,49.05-90.9,101.31q0,148.51,0,297c0,12.55,0,12.55,12.83,11.31,48.85-4.7,89.84-49.54,89.93-99.1q.29-151.3,0-302.62C384.58,8.67,384.57,8.18,384.56,7.69Z" />
        <path d="M51.71,308.46C22.75,308.37.12,330.8,0,359.7c-.12,29.24,22,51.51,51.19,51.59s51.67-22.12,51.75-51S80.53,308.54,51.71,308.46Z" />
        <path d="M462.11,0c-27.76-.65-52,23-52.55,51.31s22.54,51.76,51.24,52.19a51.21,51.21,0,0,0,52.29-50.9A52.11,52.11,0,0,0,462.11,0Z" />
        <path d="M435.68,180.31c-14.76,0-26.4,11.64-26.11,26.05a26.69,26.69,0,0,0,25.72,25.72,26,26,0,0,0,26.39-25.85C461.73,191.38,450.59,180.28,435.68,180.31Z" />
        <path d="M233.55,7.69a7.12,7.12,0,0,0-7.87-6.9l-4,.44c-52.19,5.95-90.89,49.05-90.9,101.31q0,148.51,0,297c0,12.55,0,12.55,12.83,11.31,48.86-4.7,89.84-49.54,89.93-99.1q.28-151.3,0-302.62C233.57,8.67,233.56,8.18,233.55,7.69Z" />
      </g>
    </g>
  </svg>
);

export const Burger = () => (
  <svg width="20" height="18" viewBox="0 0 20 18">
    <path fill="#999" d="M0 2V0h20v2H0zm0 8V8h20v2H0zm0 8v-2h20v2H0z" />
  </svg>
);

export const Cross = ({ mobile }) => (
  <svg height="25" width="25" viewBox="0 0 64 64">
    <line
      x1="9.37"
      x2="54.63"
      y1="9.37"
      y2="54.63"
      fill="none"
      stroke={mobile ? '#999' : '#010101'}
      strokeWidth="4"
    />
    <line
      x1="9.37"
      x2="54.63"
      y1="54.63"
      y2="9.37"
      fill="none"
      stroke={mobile ? '#999' : '#010101'}
      strokeWidth="4"
    />
  </svg>
);

export const Mail = () => (
  <svg viewBox="0 0 64 48" height="20">
    <path
      style={{ fill: 'f0f0f0' }}
      d="M63.47,5.17A8,8,0,0,0,56,0H8A8,8,0,0,0,.48,5.33,7.44,7.44,0,0,0,0,8V40a8,8,0,0,0,5,7.41A7.85,7.85,0,0,0,8,48H56a7.32,7.32,0,0,0,2.85-.56A7.84,7.84,0,0,0,63,43.89,8,8,0,0,0,64,40V8A7.86,7.86,0,0,0,63.47,5.17ZM5.33,8A2.68,2.68,0,0,1,8,5.33H56A2.68,2.68,0,0,1,58.67,8v.51l-21.26,16L33,27.92l-1,.75-1-.78-4.46-3.33L5.33,8.61Zm0,32V15.28L22.11,27.89,5.39,40.45A2,2,0,0,1,5.33,40Zm6,2.67L26.56,31.23l3.84,2.9a2.64,2.64,0,0,0,3.2,0l3.81-2.88L52.53,42.67ZM58.67,40a2,2,0,0,1-.08.56L41.84,27.89,58.67,15.17Z"
    />
  </svg>
);

export const Play = () => (
  <svg
  style={{
    filter: 'drop-shadow( 1px 2px 7px rgba(0, 0, 0, .5))',
  }}
  height="32" width="32" viewBox="0 0 512 512">
    <path
      style={{
        fill: 'white',
      }}
      d="M464.7,221.5L86.1,7.3C52.5-11.7,25,7.5,25,50v412c0,42.5,27.5,61.7,61.1,42.7l378.6-214.1
	C498.2,271.5,498.2,240.5,464.7,221.5z"
    />
  </svg>
);

export const LeftArrow = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(2.000000, 1.000000)" fillRule="nonzero" stroke="#818592" strokeWidth="3">
            <path d="M12.0412564,2.27117461 L2.5836056,11.7288254" id="Line-4" transform="translate(7.312431, 7.000000) rotate(-315.000000) translate(-7.312431, -7.000000) "></path>
            <polyline transform="translate(6.874649, 6.874649) rotate(-45.000000) translate(-6.874649, -6.874649) " points="2.04897825 11.5413159 1.81909371 2.20798259 11.9302048 2.41861482"></polyline>
        </g>
    </g>
</svg>
)
// export const Mail = () => (
//   <svg
//     viewBox="0 0 24 24"
//     fill="none"
//     strokeWidth="2"
//     height="40"
//   >
//     <path
//       stroke="f0f0f0"
//       d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
//     />
//     <polyline stroke="f0f0f0" points="22,6 12,13 2,6" />
//   </svg>
// );

// export const Mail = () => (
// <svg width="32px" height="32px" viewBox="0 0 32 32">
//     <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
//         <g fill="#f0f0f0">
//             <path d="M5.31500722,9.27391933 C5.12106043,9.45739405 5,9.71770487 5,10.0068455 L5,21.9931545 C5,22.2828882 5.11915965,22.5440384 5.31310687,22.7277332 L5.31310687,22.7277332 L13.05,16 L5.31500722,9.27391933 L5.31500722,9.27391933 L5.31500722,9.27391933 Z M27.6868931,9.27226685 C27.8808404,9.45596162 28,9.71711185 28,10.0068455 L28,21.9931545 C28,22.2822951 27.8789396,22.542606 27.6849928,22.7260807 L19.95,16 L27.6868931,9.27226685 L27.6868931,9.27226685 Z M19.2018297,16.6505829 L26.5,23 L6.5,23 L13.7981703,16.6505829 L16.5,19 L19.2018297,16.6505829 L19.2018297,16.6505829 Z M6.00359486,8 C4.89703997,8 4,8.89451376 4,9.99406028 L4,22.0059397 C4,23.1072288 4.88976324,24 6.00359486,24 L26.9964051,24 C28.10296,24 29,23.1054862 29,22.0059397 L29,9.99406028 C29,8.8927712 28.1102368,8 26.9964051,8 L6.00359486,8 L6.00359486,8 Z M16.5,17.7000122 L26.5,9 L6.5,9 L16.5,17.7000122 L16.5,17.7000122 Z" />
//         </g>
//     </g>
// </svg>
// );
