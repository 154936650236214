import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import styled from 'styled-components';
import { Play } from '../icons';
import debounce from 'lodash/debounce';

const PlayContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1000;
`;

const CenteredVideo = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem 0;
`;

const PostItemContainer = styled.div`
  position: relative;
  z-index: 100;
  transition: ${(props) => props.theme.transitions.boom.transition};
  height: auto;
  width: 100%;
  flex-basis: calc(99.9% * 1 / 2 - 10rem);
  max-width: calc(99.9% * 1 / 2 - 10rem);
  width: calc(99.9% * 1 / 2 - 10rem);
  @media (max-width: 1170px) {
    flex-basis: calc(99.9% * 1 / 2 - 5rem);
    max-width: calc(99.9% * 1 / 2 - 5rem);
    width: calc(99.9% * 1 / 2 - 5rem);
    width: 100%;
    margin-bottom: 2rem;
  }
  @media (max-width: 750px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  ${(props) =>
    props.title === 'Mudpots' &&
    `
  display: flex;
  flex-direction: column;
  justify-content: center;
  `}
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  marginbottom: 0;
`;

const VidWrap = styled.div`
  width: 100%;
  height: ${(props) => (props.vidHeight ? props.vidHeight + 'px' : '100%')};
  background: #000;
  position: relative;
  transition: ${(props) => props.theme.transitions.boom.transition};
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6);
`;

const NarrativeDetails = (props: {
  link: string;
  path: string;
  noPlayBtn?: boolean;
  thumbnail?: FluidObject;
  title?: string;
  type?: string;
  handleClick?: Function;
  clicked?: string;
  children;
}) => {
  const url =
    props.type === 'Branded'
      ? 'www.youtube.com/embed/'
      : 'player.vimeo.com/video/';

  const path = props.link.substring(props.link.lastIndexOf('/') + 1);
  const src = `https://${url}${path}?autoplay=1&autopause=0"`;
  // const src = `https://${url}${path}`;

  const vidRef = React.useRef(null);
  const [vidHeight, setVidHeight] = React.useState(Number);
  React.useEffect(() => {
    const calcHeight = debounce(
      // @ts-ignore
      () => setVidHeight(vidRef.current.offsetWidth * 0.5639766942258995),
      50
    );
    calcHeight();
    window.addEventListener('resize', calcHeight);
    return () => window.removeEventListener('resize', calcHeight);
  }, []);

  return (
    <PostItemContainer key={props.path} id={props.title} title={props.title}>
      <CenteredVideo id={props.title} onClick={props.handleClick}>
        <VidWrap ref={vidRef} link={props.link} vidHeight={vidHeight}>
          {props.link && props.clicked === props.title ? (
            <iframe
              id={props.title}
              src={src}
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                marginBottom: 0,
              }}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          ) : (
            <StyledImg fluid={props.thumbnail} />
          )}
        </VidWrap>
        {props.link && !props.noPlayBtn && props.clicked !== props.title && (
          <PlayContainer>
            <Play />
          </PlayContainer>
        )}
      </CenteredVideo>
      {props.children ? props.children : null}
    </PostItemContainer>
  );
};

export default NarrativeDetails;
