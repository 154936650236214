import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import 'typeface-work-sans';
import 'typeface-open-sans';
import 'typeface-montserrat';
// import 'typeface-fira-code';
// import 'typeface-reem-kufi';
import theme from '../../config/theme';
import reset from '../styles/reset';
// import { TransitionPortal } from 'gatsby-plugin-transition-link';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import favicon from '../images/favicon.ico';

const GlobalStyles = createGlobalStyle`
  *::before,
  *::after {
    box-sizing: border-box;
  }
  ::selection {
    color: white;
    background-color: #004988;
  }
  html {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    height: 100vh;
  }
  body {
    height: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    color: black;
    // font-family: 'Work Sans', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background: white;
    font-size: 18px;
    font-weight: 300;
  }

h1, h2, h3, h4, h5{
  font-weight: 400;
}
  #___gatsby,
  #gatsby-focus-wrapper,
  .tl-edges,
  .tl-wrapper {
    height: 100%;
  }
  a {
    transition: all 0.3s ease-in-out;
    color: black;
    text-decoration: underline;
    &:hover,
  }

  .gatsby-plugin-transition-link-portal{
    width: 100%;
  }
  ${reset}

`;

const Wrapper = styled.div`
  height: 100%;
  /* display: grid; */
  /* grid-template-columns: 1fr; */
`;

const SideBarInner = styled.div`
  /* position: sticky; */
  position: relative;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  z-index: 5;
`;

const Nav = styled.div`
  width: 100%;
  display: flex;
  color: #222;
  text-align: end;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 1rem 3rem; */
  padding: 1rem 8.75rem;
  @media (max-width: 1170px) {
    padding: 1rem 5.75rem;
  }
  @media (max-width: 750px) {
    padding: 1rem 1.75rem;
  }
`;

const NavItemWrap = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  a {
    text-decoration: none;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover,
    &:focus,
    &.navlink-active {
      color: #b59651;
    }
    @media (max-width: 950px) {
      margin-left: 0.75rem;
      font-size: 0.9rem;
    }

    @media (max-width: 480px) {
      margin-left: 0.5rem;
    }
  }
`;

const Main = styled.main`
  /* height: 100%; */
  background: #f7f7f7;
  z-index: 2;
`;

const NameContainer = styled.div`
  a {
    text-decoration: none;
    display: flex;
    div {
      margin-right: 0.5rem;
      font-size: 1.5rem;
    }
    h3 {
      margin: 0;
      margin-right: 0.5rem;
    }
    @media (max-width: 950px) {
      div {
        margin-right: 0.5rem;
        font-size: 1.2rem;
      }
    }
    @media (max-width: 820px) {
      flex-direction: column;
      div {
        font-size: 1.1rem;
      }
    }
    @media (max-width: 650px) {
      div {
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
`;

// const isPartiallyActive = ({ isPartiallyCurrent }: { isPartiallyCurrent: boolean }) =>{
const isPartiallyActive = ({ isCurrent }) =>
  isCurrent
    ? { className: 'navlink-active navlink' }
    : { className: 'navlink' };

const PartialNavLink = ({
  children,
  to,
  ...rest
}: {
  children: React.ReactNode;
  to: string;
}) => (
  <AniLink fade getProps={isPartiallyActive} to={to} {...rest}>
    {children}
  </AniLink>
);

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
    <GlobalStyles />
    <Wrapper>
      {/* <TransitionPortal> */}
      <SideBarInner>
        <Nav>
          <NameContainer>
            <AniLink fade to="/">
              <div>DAVID</div>
              <div>FORMENTIN</div>
            </AniLink>
          </NameContainer>
          <NavItemWrap>
            <PartialNavLink to="/narrative">NARRATIVE</PartialNavLink>
            <PartialNavLink to="/branded">BRANDED</PartialNavLink>
            {/* <PartialNavLink to="/about">ABOUT</PartialNavLink> */}
            <PartialNavLink to="/contact">CONTACT</PartialNavLink>
          </NavItemWrap>
        </Nav>
      </SideBarInner>
      {/* </TransitionPortal> */}
      <Main>{children}</Main>
    </Wrapper>
  </ThemeProvider>
);

const Input = styled.input`
  border: none;
  text-align: center;
  width: 100%;
  margin: 39vh 0;
  outline: none;
`;

export default Layout;
